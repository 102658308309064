import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { LandingPage } from "./pages/LandingPage";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { SecondPage } from "./pages/SecondPage";
import { ResourcesPage } from "./pages/ResourcesPage";
import { DiversityPage } from "./pages/DiversityPage";
import { Breadcrumbs } from "./breadcrumbs";
import configurationFile from "./VetNav benefits.csv";
import { WelcomePopUp } from "./WelcomePopUp";
import './App.css';

const defaultDemoDict = {
  "Demographics": {
    Minority: false,
    Woman: false,
    "Prefer not to Disclose": false,
  },
  "VA-rated disability": {
    Yes: false,
    No: false,
  },
  "Transitioning": {
    Yes: false,
    No: false,
    description: 'Transitioning veterans are in the process of leaving the military to join civilian life.'
  },
  "Military Status": {
    "Active Duty": false,
    "National Guard": false,
    "Reserve": false,
    "Retiree": false,
    Veteran: false,
    "Prefer not to Disclose": false,
  },
  "Branch of Service": {
    "Air Force": false,
    "Army": false,
    "Coast Guard": false,
    "Marine Corps": false,
    Navy: false,
    "Space Force": false,
    "Prefer not to Disclose": false,
  },
  "Service Era": {
    "Before 09/11": false,
    "Post 09/11": false,
  },
}

function App() {
  const [configurationData, setConfigurationData] = useState([])
  const [selections, setSelections] = useState([])
  const [resourceObjs, setResourceObjs] = useState({})
  const [questions, setQuestions] = useState([])
  const [isWelcome, setIsWelcome] = useState(null)
  const [screenSize, setScreenSize] = useState('default')
  const [diversityDict, setDiversityDict] = useState(defaultDemoDict)

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth)
      if (window.innerWidth < 700) {
        setScreenSize('small')
      } else {
        setScreenSize('default')
      }
    };
    handleResize()
    window.addEventListener('resize', handleResize);
  }, []);

  const primaryColor = configurationData.data && configurationData.data[1][13] ? configurationData.data[1][13] : '#1D3D78'
  const secondaryColor = configurationData.data && configurationData.data[1][14] ? configurationData.data[1][14] : '#4370d1'
  const hoverColor = configurationData.data && configurationData.data[1][15] ? configurationData.data[1][15] : 'aliceBlue'
  const iconColor = configurationData.data && configurationData.data[1][16] ? configurationData.data[1][16] : 'white'
  console.log(configurationData.data ? configurationData.data[1] : "")
  console.log(iconColor)

  Papa.parse(configurationFile, {
    download: true,
    complete: (results) => {
      if (configurationData.length < 1) {
        setConfigurationData(results)
      }
    }
  })
  useEffect(() => {
    if(configurationData?.data?.length > 0) {
      const outputDict = {}
      configurationData.data.forEach(configRow => {
          outputDict[configRow[4]] = {
            linkName: configRow[0],
            url: configRow[2],
            description: configRow[3],
          }
      })
      setResourceObjs({...outputDict})
    }
  }, [configurationData])

  useEffect(() => {
    if (configurationData) {
      const tempQuestions = configurationData?.data?.filter(configurationRow => (
        configurationRow[7] === selections[selections.length - 1]
      )).map(categoryRow => (
          categoryRow[5]
      ));
      if (tempQuestions) {
        setQuestions([...tempQuestions])
      }
    }
  }, [selections])

  const getContent = (selectionsArray, questionsArray) => {
    if (selectionsArray.length === 0) {
      return (
        <LandingPage
          configurationData={configurationData}
          setSelections={setSelections}
          selections={selectionsArray}
          setIsWelcome={setIsWelcome}
          screenSize={screenSize}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          hoverColor={hoverColor}
          iconColor={iconColor}
        />
      )
    } else {
      if (questionsArray.length > 0) {
        return (
          <SecondPage
            configurationData={configurationData.data}
            setSelections={setSelections}
            selections={selectionsArray}
            questions={questionsArray}
            screenSize={screenSize}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            hoverColor={hoverColor}
            iconColor={iconColor}
            diversityDict={diversityDict}
            setDiversityDict={setDiversityDict}
          />
        )
      } else {
        if (diversityDict.complete) {
          return (
            <div>
              <ResourcesPage
                selections={selections}
                resources={resourceObjs}
                configurationData={configurationData}
                setSelections={setSelections}
                diversityDict={diversityDict}
                setDiversityDict={setDiversityDict}
                isWelcome={isWelcome}
                setIsWelcome={setIsWelcome}
                screenSize={screenSize}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                hoverColor={hoverColor}  
              />
            </div>
          )
        } else {
          return (
            <DiversityPage
              configurationData={configurationData}
              setSelections={setSelections}
              selections={selectionsArray}
              questions={questionsArray}
              diversityDict={diversityDict}
              setDiversityDict={setDiversityDict}
              setIsWelcome={setIsWelcome}
              screenSize={screenSize}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              hoverColor={hoverColor}
              iconColor={iconColor}
            />
          )
        }
      }
    }
  }
  console.log(screenSize)
  return (
    <div className="App">
      {isWelcome && (
        <WelcomePopUp 
          isWelcome={isWelcome}
          setIsWelcome={setIsWelcome}
          popUpType="welcome"
          screenSize={screenSize}
        />
      )}
      <Header
        screenSize={screenSize}
        primaryColor={primaryColor}
        iconColor={iconColor}
      />
      {selections.length > 0 && (
        <Breadcrumbs 
          selections={selections}
          setSelections={setSelections}
          configurationData={configurationData.data}
          diversityDict={diversityDict}
          setDiversityDict={setDiversityDict}
          screenSize={screenSize}
          defaultDemoDict={defaultDemoDict}
        />
      )}
      {getContent(selections, questions)}
      <Footer
        screenSize={screenSize}
        primaryColor={primaryColor}
        iconColor={iconColor}
      />
    </div>
  );
}

export default App;
