import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Breadcrumbs = (props) => {
    const {
        configurationData,
        selections,
        setSelections,
        setDiversityDict,
        screenSize,
        defaultDemoDict,
    } = props
    return (
        <div className="breadcrumb-row">
            <div
                className="standard-breadcrumb"
                onClick={() => {
                    setSelections([])
                    setDiversityDict(defaultDemoDict)
                }}
            >
                Home
            </div>
            <div className="breadcrumb-chevron">
                <ChevronRightIcon style={{fontSize: screenSize === 'small' ? '3.3vw' : '1.3vw'}}/>
            </div>
            {selections?.map((selection, i) => (
                <>
                    <div 
                        className={`${i !== selections.length - 1 ? "standard-breadcrumb" : "final-breadcrumb"} ${screenSize === 'small' ? 'small-screen' : ''}`}
                        onClick={() => {
                            setSelections(selections.slice(0, i + 1))
                            setDiversityDict(defaultDemoDict)
                        }}
                    >
                        {configurationData.find(configRow => (
                            configRow[5] === selection
                        ))[10]}
                    </div>
                    { i !== selections.length - 1 && (
                        <div className="breadcrumb-chevron">
                            <ChevronRightIcon style={{fontSize: screenSize === 'small' ? '3.3vw' : '1.3vw'}}/>
                        </div>
                    )}
                </>
            ))}
        </div>
    )
}