import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const PopupGraphic = (props) => {
    const {
        configurationData,
        selections,
        setSelections,
    } = props

    return (
        <div className="pop-up-graphic">
            <div className="pop-up-graphic-row">
                <div className="pop-up-graphic-box gray">
                    <div>
                        Page 1
                    </div>
                    <div className='graphic-subtext'>
                        Categories
                    </div>
                </div>
                <ChevronRightIcon style={{fontSize: "2em"}}/>
                <div className="pop-up-graphic-box">
                    <div>
                        Page 2-3
                    </div>
                    <div className='graphic-subtext'>
                        Topic Questions
                    </div>
                </div>
                <ChevronRightIcon style={{fontSize: "2em"}}/>
                <div className="pop-up-graphic-box">
                    <div>
                        Page 4
                    </div>
                    <div className='graphic-subtext'>
                        Military Background
                    </div>
                </div>
                <ChevronRightIcon style={{fontSize: "2em"}}/>
                <div className="pop-up-graphic-box">
                    <div>
                        Page 5
                    </div>
                    <div className='graphic-subtext'>
                        Resources
                    </div>
                </div>
            </div>
            <div className="graphic-call-out">
                <div>
                    <KeyboardArrowUpIcon />
                </div>
                <div>
                    You are here
                </div>   
            </div>
        </div>
    )
}