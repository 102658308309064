import React, {useState} from "react";
import findJob from "../images/find-job.png";
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

export const DiversityPage = (props) => {
    const {
        diversityDict,
        setDiversityDict,
        setIsWelcome,
        primaryColor,
        secondaryColor,
        screenSize,
    } = props

    const isSmallScreen = screenSize == 'small';
    const [hoveredPill, setHoveredPill] = useState("")
    console.log(diversityDict)
    return (
        <div className="diversity-page-content">
            <div
                className={`header-text ${isSmallScreen ? "small-screen" : ""}`}
                style={{
                    color: primaryColor,
                }}
            >
                Military Information
            </div>
            <div className={`question-banner-description diversity ${isSmallScreen ? "small-screen" : ""}`}>
            We’ve put together a list of questions that can help identify resources specific to you. This information will not be shared. Please select all that apply.​
            </div>
            <div className="diversity-page-container">
                <div style={{width: isSmallScreen ? "100%" : "79%", display: "flex", flexDirection: "column"}}>
                    <div className={`diversity-page-list ${isSmallScreen ? "small-screen" : ""}`}>
                        {
                            Object.keys(diversityDict)?.map(diversityKey => (
                                diversityKey !== "complete" && (
                                    <div 
                                        key={diversityKey}
                                        className="diversity-page-list-item"
                                    >
                                        <div 
                                            className="diversity-header"
                                            style={{
                                                color: primaryColor,
                                            }}
                                        >
                                            {diversityKey}
                                            {diversityDict[diversityKey]['description'] && !isSmallScreen && (
                                                <Tooltip 
                                                    title={
                                                        <div className='demo-tool-tip'>
                                                            {diversityDict[diversityKey]['description']}
                                                        </div>
                                                    }
                                                    placement="top-start"
                                                    slotProps={{
                                                        tooltip: { sx: { fontSize: '1vh' } },
                                                        popper: {
                                                            modifiers: [
                                                            {
                                                                name: 'offset',
                                                                options: {
                                                                offset: [0, -14],
                                                                },
                                                            },
                                                            ],
                                                        },
                                                        }}
                                                >
                                                    <HelpIcon style={{fontSize: '1vw'}}/>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div className="diversity-options-container">
                                            {
                                                Object.keys(diversityDict[diversityKey]).map(optionKey => {
                                                    if (optionKey !== 'description') {
                                                        return (
                                                            <div
                                                                className={`diversity-option-pill ${diversityDict[diversityKey][optionKey] ? 'selected' : ''}`}
                                                                // style={{
                                                                //     color: diversityDict[diversityKey][optionKey] ? primaryColor,
                                                                //     borderColor: primaryColor,
                                                                //     backgroundColor: hoveredPill === `${diversityKey}-${optionKey}` ? hoverColor : 'white'
                                                                // }}
                                                                key={`${diversityKey}-${optionKey}`}
                                                                onMouseEnter={
                                                                    () => {
                                                                        setHoveredPill(`${diversityKey}-${optionKey}`)
                                                                    }
                                                                }
                                                                onMouseLeave={
                                                                    () => {
                                                                        setHoveredPill("")
                                                                    }
                                                                }
                                                                onClick={() => setDiversityDict(
                                                                    {
                                                                        ...diversityDict,
                                                                        [diversityKey]: {
                                                                            ...diversityDict[diversityKey],
                                                                            [optionKey]: !diversityDict[diversityKey][optionKey]
                                                                        }
                                                                    }
                                                                )}
                                                            >
                                                                {optionKey}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            ))
                        }
                    </div>
                    <div className="button-container">
                        <div
                            className={`next-button ${isSmallScreen ? "small-screen" : ""}`}
                            style={{
                                backgroundColor: secondaryColor,
                            }}
                            onClick={() => setDiversityDict({ ...diversityDict, complete: true})}
                        >
                            Submit
                        </div>
                    </div>
                </div>
                {!isSmallScreen && (
                    <div className="question-banner-image  demographics">
                        <img 
                            className="banner-image"
                            src={findJob}
                            alt="bannerImage"
                        />
                    </div>
                )}
            </div>
            {!isSmallScreen && (
                <div className="information-icon">
                    <HelpIcon style={{fontSize: '2.6vw', color: secondaryColor}} onClick={()=>setIsWelcome('demographics')}/>
                </div>
            )}
        </div>
    )
}