export const Header = (props) => {
    const {
        screenSize,
        primaryColor,
        iconColor,
    } = props;
    console.log(primaryColor)
    console.log(iconColor)
    return (
        <div 
            className={`app-header ${screenSize === 'small' ? 'small-screen' : ''}`}
            style={{
                backgroundColor: primaryColor,
                color: iconColor,
            }}
        >
            <div>
            Veterans Navigator
            </div>
            <div className={`header-description ${screenSize === 'small' ? 'small-screen' : ''}`}>
                Providing resources to NYC veterans and their families
            </div>
        </div>
    )
}