import React, { useState } from "react";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SchoolIcon from '@mui/icons-material/School';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import HouseIcon from '@mui/icons-material/House';
import GroupsIcon from '@mui/icons-material/Groups';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BlindIcon from '@mui/icons-material/Blind';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FlagIcon from '@mui/icons-material/Flag';
import HelpIcon from '@mui/icons-material/Help';


import LocalHospitalIcon from '@mui/icons-material/LocalHospitalOutlined';


const iconDict = {
    Career: <BusinessCenterIcon style={{fontSize: "3.1vh"}} />,
    Education: <SchoolIcon style={{fontSize: "3.1vh"}} />,
    Health: < LocalHospitalIcon style={{fontSize: "3.1vh"}} />,
    Housing: <HouseIcon style={{fontSize: "3.1vh"}} />,
    Social: <GroupsIcon style={{fontSize: "3.1vh"}} />,
    Financial:<AttachMoneyIcon style={{fontSize: "3.1vh"}} />,
    "Disability & Pension": <BlindIcon style={{fontSize: "3.1vh"}} />,
    Memorial: <FlagIcon style={{fontSize: "3.1vh"}} />,
    Records: <FileCopyIcon style={{fontSize: "3.1vh"}} />,
}

export const LandingPage = (props) => {
    const {
        configurationData,
        selections,
        setSelections,
        setIsWelcome,
        screenSize,
        primaryColor,
        secondaryColor,
        hoverColor,
        iconColor,
    } = props

    const [hoveredCategory, setHoveredCategory] = useState(null)

    const categories = configurationData?.data?.filter(configurationRow => (
        configurationRow[6] === "1"
    )).map(categoryRow => (
        categoryRow[5]
    ));
    const descriptions = configurationData?.data?.filter(configurationRow => (
        configurationRow[6] === "1"
    )).map(categoryRow => (
        categoryRow[11]
    ));

    return (
        <div className="landing-page-content">
            <div 
                className={`header-text home ${screenSize === 'small' ? 'small-screen' : ''}`}
                style={{
                    color: primaryColor,
                }}
            >
                Welcome to Veterans Navigator!
            </div>
            <div className={`subheader-text ${screenSize === 'small' ? 'small-screen' : ''}`}>
                We've put together a list of resources to help Veterans like you find support in every aspect of life.
            </div>
            <div
                className={`header-text small ${screenSize === 'small' ? 'small-screen' : ''}`}
                style={{
                    color: primaryColor,
                }}
            >
                What would you like to focus on today?
            </div>
            <div className={`landing-page-list ${screenSize === 'small' ? 'small-screen' : ''}`}>
                {
                    categories?.map((category, i) => {
                        let location = 'left'
                        if ((i + 3) % 3 === 2) {
                            location = 'right'
                        } else if ((i + 3) % 3 === 1) {
                            location = 'center'
                        }
                        return (
                            <div
                                key={category}
                                className={`landing-page-list-item ${screenSize === 'small' ? 'small-screen' : ''} ${categories.length > 9  ? 'multi-cat' : ''} ${location}`}
                                style={{
                                    color: primaryColor,
                                    backgroundColor: hoveredCategory === category ? hoverColor : "white"
                                }}
                                onClick={() => (setSelections([...selections, category]))}
                                onMouseEnter={() => setHoveredCategory(category)}
                                onMouseLeave={() => setHoveredCategory(null)}
                            >
                                {screenSize !== 'small' && ( 
                                    <div 
                                        className="landing-page-list-icon"
                                        style={{
                                            backgroundColor: primaryColor,
                                            color: iconColor,
                                        }}
                                    >
                                        <div className="briefcase-icon landing">
                                            {iconDict[category]}
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`icon-label ${screenSize === 'small' ? 'small-screen' : ''}`}
                                    style={{

                                        marginLeft: screenSize === 'small' || hoveredCategory === category ? 0 : '-3.5vh',
                                        fontSize: screenSize !== 'small' && hoveredCategory === category ? '2.3vh' : '3.5vh',
                                    }}>
                                    {screenSize !== 'small' && hoveredCategory === category ? descriptions[i] : category}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {screenSize !== 'small' && (
                <div 
                    className={`information-icon ${screenSize === 'small' ? 'small-screen' : ''}`}
                    style={{
                        color: secondaryColor,
                    }}
                >
                    <HelpIcon style={{fontSize: screenSize === 'small' ? '7.5vw' : '2.6vw'}} onClick={()=>setIsWelcome('welcome')}/>
                </div>
            )}
            {screenSize !== 'small' && (
                <img className="footer-image" src={"../images/veterans-banner-desktop-1.png"} alt="footerImage" />
            )}
        </div>
    )
}