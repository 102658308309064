import React, { useState, useEffect } from "react";
import axios from 'axios';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { PopupGraphic } from "./PopupGraphic";

export const WelcomePopUp = (props) => {
    const {
        isWelcome,
        setIsWelcome,
        screenSize,
    } = props
    const [starNumber, setStarNumber] = useState(0)
    const [selectedStar, setSelectedStar] = useState(0)
    const [commentString, setcommentString] = useState(null)
    const [defaultString, setDefaultString] = useState("Please enter your comments here")
    const [message, setMessage] = useState('');

    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.baseURL = '/api/';

    const postUpdate = (stars, comment) => {
        // axios.post('http://localhost:8000/api/submit-feedback/', {
        //     star_rating: stars ? stars - 1 : null,
        //     comments: comment,
        // })
        //     .then(response => {
        //         setMessage(response.data.message);
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
        axios({
            method:'post',
            url:'api/submit-feedback/',
            baseURL: '/',
            data: {
                star_rating: stars ? stars - 1 : null,
                comments: comment,
              }
           })
           .then(() => {
                setIsWelcome(false)
           })
           .catch(error => {
               console.log(error);
           });
    };

    const getContent = (welcomeType) => {
        if (welcomeType === 'welcome') {
            return (
                <>
                    <div className="pop-up-header">
                        Welcome to Veterans Navigator!
                    </div>
                        <PopupGraphic />
                    <div className={`pop-up-content ${screenSize === 'small' ? 'small-screen' : ''}`}>
                    Over the next 4 pages, you will find categories, questions, and military background options that will lead you to a group of resources tailored to your unique needs. Click on the topics of your interest and find out what services and benefits are available to you!
                    </div>
                    <div className="pop-up-bottom-row">
                        <div className={`next-button small ${screenSize === 'small' ? 'small-screen' : ''}`} onClick={() => setIsWelcome(null)}>
                            Start
                        </div>
                    </div>
                </>
            )
        }
        if (welcomeType === 'demographics') {
            return (
                <>
                    <div className="pop-up-header">
                        Military Information
                    </div>
                    <div className="pop-up-content" style={{fontSize:'2vh'}}>
                        Welecome to the military information page! You can select as many of the options on the page to access resource that cater to the included demographics. If you would prefer not to answer, you can click on the "Submit" button to proceed.
                    </div>
                    <div className="pop-up-bottom-row">
                        <div className="next-button small" onClick={() => setIsWelcome(null)}>
                            Close
                        </div>
                    </div>
                </>
            )
        }
        return (
            <>
                <div className="pop-up-header">
                    We'd love to hear your feedback!
                </div>
                <div className="pop-up-content">
                    <div>
                        How would you rate your experience with Veterans Navigator?
                    </div>
                    <div className="star-row">
                        {
                            [1,2,3,4,5].map(int => (
                                <div 
                                    onMouseEnter={() => {
                                        setStarNumber(int + 1)
                                    }}
                                    onMouseLeave={() => {
                                        setStarNumber(0)
                                    }}
                                    onClick={() => {
                                        setSelectedStar(int + 1)
                                    }}
                                >
                                    {(starNumber || selectedStar) <= int ? 
                                        <StarBorderIcon
                                            style={{
                                                height: 50,
                                                width: 50,
                                                color: "#1D3D78"
                                            }}
                                        />
                                    : 
                                        <StarIcon
                                            style={{
                                                height: 50,
                                                width: 50,
                                                color: "#1D3D78"
                                            }}
                                        />
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div className="welcome-pop-up-text">
                        Please enter any additional comments below:
                    </div>
                    <div className="pop-up-comments">
                        <textarea 
                            className="pop-up-comments-box"
                            placeholder={defaultString}
                            value={commentString}
                            onChange={event=>setcommentString(event.target.value)}
                            onClick={()=>setDefaultString('')}
                        />
                    </div>
                </div>
                <div className="pop-up-bottom-row">
                    <div className={`next-button small ${commentString || selectedStar ? '' : 'gray'}`} onClick={() => postUpdate(selectedStar, commentString)}>
                        Submit
                    </div>
                    <div className="next-button small" onClick={() => setIsWelcome(false)}>
                        Close
                    </div>
                </div>
            </>
        )
    }
    console.log(isWelcome)
    return (
        <div>
            <div className="screen-dim" onClick={() => setIsWelcome(null)}/>
            <div className={`welcome-pop-up ${isWelcome === 'welcome' ? '' : 'feedback'} ${screenSize === 'small' ? 'small-screen' : ''}`}>
                {getContent(isWelcome)}
            </div>
        </div>
    )
}