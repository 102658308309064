import React, {useState, useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const descriptionText = 'Congratulations, this is the end of your journey. Click on the individual resources to learn more about them by accessing their sites directly. Additionally, if you’d like to connect with a representative from NYC DVS to discuss your options, click on “Additional Help”.'

export const ResourcesPage = (props) => {
    const {
        resources,
        selections,
        configurationData,
        diversityDict,
        setIsWelcome,
        primaryColor,
        secondaryColor,
        screenSize,
    } = props

    console.log(324323423)
    const isSmallScreen = screenSize === 'small';
    console.log(screenSize === 'small')
    const finalSelection = selections[selections?.length - 1]
    const finalSelectionRow = configurationData.data.find(configRow => (
        configRow[5] === finalSelection
    ))
    const formattedResources = finalSelectionRow[9].split("&&")
    const finalSelectionCategories = finalSelectionRow[8].split(";")

    useEffect(() => {
        if (finalSelectionCategories.length === 1) {
            setExpandedCategory([finalSelectionCategories[0]])
        }
    }, [selections])
    const [expandedCategory, setExpandedCategory] = useState([])

    const handleSelectCategory = (category, isExpanded) => {
        let newExpandedList = expandedCategory
        if (isExpanded) {
            newExpandedList = expandedCategory.filter(expandedCat => (
                expandedCat !== category
            ))
        } else {
            newExpandedList.unshift(category)
        }
        setExpandedCategory([...newExpandedList])
    }
    
    const formattedResourceObjs = finalSelectionCategories.map((category, i) => {
        const finalSelectionResourceString = formattedResources[i]
        const finalSelectionResources = finalSelectionResourceString?.length > 0 ?
            finalSelectionResourceString.split(";") :
            null
        let resourceObjs = finalSelectionResources?.map(resourceId => {
            const resourceObj = resources[resourceId]
            if (resourceId) {
                return(
                    <div className={`resource-row ${isSmallScreen ? 'small-screen' : ''}`}>
                        <div className="resource-link">
                            <a href={resourceObj?.url} target="_blank">
                                {`${resourceObj?.linkName}`}
                            </a>
                        </div>
                        <div>
                            {`${resourceObj?.description}`}
                        </div>
                    </div>
                )
            }
        })
        if (category === "Are you looking for resources tailored to your experience:") {
            const filteredDiversity = Object.values(diversityDict).filter(bool => (
                bool
            ))
            if (filteredDiversity.length < 2) {
                return null
            }
        }
        const isExpanded = expandedCategory.find(expandedCat => (
            expandedCat === category
        ))
        return (
            <div className={`resource-box-container ${isExpanded ? '': 'hidden'}`}>
                <div
                    className={`resource-header`} 
                    style={{
                        color: primaryColor
                    }}
                    onClick={() => handleSelectCategory(category, isExpanded)}
                >
                    <div>
                        {category}
                    </div>
                    <div className="expand-icon">
                        {isExpanded ? <RemoveIcon style={{height: '3vh', width: '3vh'}}/> : <AddIcon style={{height: '3vh', width: '3vh'}}/>}
                    </div>
                </div>
                    {isExpanded && (
                        <div className={`resource-list`}>
                            {resourceObjs}
                        </div> 
                    )}
            </div>
        )
    })
    return (
        <>
            <div className="resource-page-content">
                <div
                    className={`header-text ${isSmallScreen ? "small-screen" : ""}`}
                    style={{
                        color: primaryColor,
                    }}
                >
                    {selections[selections.length - 1]}
                </div>
                <div
                    className={`question-banner-description ${isSmallScreen ? "small-screen" : ""}`}
                    style={{
                        color: primaryColor
                    }}
                >
                    {descriptionText}
                </div>
                <div className={`resource-content ${isSmallScreen ? 'small-screen' : ''}`}>
                    <div className="resource-left-panel">
                        <div className="resource-boxes">
                            {formattedResourceObjs}
                        </div>
                        <div className={`resource-buttons ${isSmallScreen ? 'small-screen' : ""}`}>
                            <div
                                className={`next-button ${isSmallScreen ? 'small-screen' : ""} blue`}
                                onClick={()=>setIsWelcome('feedback')}
                                style={{
                                    backgroundColor: secondaryColor
                                }}
                            >
                                Feedback
                            </div>
                            <a 
                                className={`next-button ${isSmallScreen ? 'small-screen' : ""} blue right`} href={"https://www.nyc.gov/site/veterans/services/services.page"} target="_blank"
                                style={{
                                    backgroundColor: secondaryColor
                                }}
                            >
                                Additional Help
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}