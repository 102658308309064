import React, {useState, useRef, useEffect} from "react";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const descriptionDict = {
    Career: "VetNav is committed to serving veterans who are looking for meaningful employment and opportunities to advance economically. VetNav ensures that veterans receive access to the resources they need to start a new career in NYC. ",
    Education: "VetNav helps veterans complete their education as a first step towards realizing a purpose-driven life. VetNav provides a list of resources to finance your education, receive tutoring, find a mentorship program, and more. ",
    Health: "VetNav prioritizes the physical and mental health of NYC's veteran community. VetNav connects veterans to the services they need to meet their unique health challenges and help manage chronic illnesses, disabilities, injuries, and mental health challenges. ",
    Housing: "VetNav connects all veterans to the housing services that are right for them. VetNav provides a comprehensive resource guide for veterans at every stage of their housing journey. ",
    Social: "VetNav connects veterans to programs and resources that promote social enrichment, including volunteer opportunities, civic engagement, professional networking, and peer support resources. ",
    Financial: "VetNav supports veterans and their families who are experiencing financial hardships. VetNav also provides information on investing, financial tools, and how to protect investments. ",
    "Disability & Pension": "VetNav helps veterans looking to secure disability compensation from the VA. VetNav connects veterans with disabilities to employment opportunities, job training programs, resources to modify their homes, and more. ",
    Memorial: "VetNav ensures that veterans receive dignified funerals and burial services. ",
    Records: "VetNav assists veterans looking for records information and discharge upgrade assistance. VetNav also connects veterans to free legal assistance. ",
}

export const SecondPage = (props) => {
    const {
        questions,
        selections,
        setSelections,
        configurationData,
        screenSize,
        primaryColor,
        hoverColor,
        iconColor,
        diversityDict,
        setDiversityDict,
    } = props


    const [hovered, setHovered] = useState(null)
    const pictureRef = useRef(null);

    let emergencyButton = null;
    if (selections[0] === "None") {
        emergencyButton = ( 
            <a href={"https://www.mountsinai.org/locations/urgent-care"} target="_blank" className="emergency-button">
                 {`Are you in need of immediate medical assistance?`}
             </a>
        )
    }

    const getImage = (selections) => {
        const alias = configurationData.find(configRow => (
            configRow[5] === selections[selections.length - 1]
        ))[10]
        console.log(323232332)
        console.log(`../images/${alias.toLowerCase()}-image.png`)
        return `../images/${alias.toLowerCase()}-image.png`
    }
    return (
        <div className={`second-page-content ${screenSize === 'small' ? 'small-screen' : ''}`} >
            <div
                className={`header-text long ${screenSize === 'small' ? 'small-screen' : ''}`}
                style={{
                    color: primaryColor,
                }}
            >
                {selections[selections.length - 1]}
            </div>
            <div className={`question-banner-description ${screenSize === 'small' ? 'small-screen' : ''}`}>
                {descriptionDict[selections[0]]}
            </div>
            <div className="page-content">
                {emergencyButton && (
                    <div className="emergency-button-container">
                        {emergencyButton}
                    </div>
                )}
                <div className={`second-page-list ${emergencyButton ? 'short' : ''} ${screenSize === 'small' ? 'small-screen' : ''}`} id="child">
                    {
                        questions?.map(category => {
                            const categoryRow = configurationData.find(csvRow => (
                                csvRow[5] === category
                            ))
                            const description = categoryRow[11]
                            const isEmergency = categoryRow[12] == 1
                            return (
                                <div className="question-item-container">
                                    <div 
                                        key={category}
                                        className={`second-page-list-item ${isEmergency ? 'emergency' : ''} ${screenSize === 'small' ? 'small-screen' : ''}`}
                                        style={{
                                            color: primaryColor,
                                            borderColor: isEmergency ? 'red' : primaryColor,
                                            backgroundColor: hovered === category ? hoverColor : 'white'
                                        }}
                                        onClick={() => {
                                            setSelections([...selections, category])
                                            if (isEmergency) {
                                                setDiversityDict({ ...diversityDict, complete: true})
                                            }
                                        }}
                                        onMouseEnter={() => setHovered(category)}
                                        onMouseLeave={() => setHovered(null)}
                                    >
                                        <div className="second-page-list-item-content">
                                            <div className={`second-page-list-item-row ${screenSize === 'small' ? 'small-screen' : ''}`}>
                                                <div 
                                                    className={`second-page-list-icon ${screenSize === 'small' ? 'small-screen' : ''}`}
                                                    style={{
                                                        backgroundColor: primaryColor,
                                                        color: iconColor,
                                                    }}
                                                >
                                                    <div 
                                                        className="briefcase-icon"
                                                    >
                                                        <ChevronRightIcon 
                                                            style={{
                                                                fontSize: screenSize === 'small' ? '3.4vw' : '1.4vw',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    {category}
                                                </div>
                                            </div>
                                            <div className="second-page-list-item-divider" />
                                            <div className={`second-page-list-item-row bottom ${screenSize === 'small' ? 'small-screen' : ''}`}>
                                                <div className={`second-page-list-item-subheader ${screenSize === 'small' ? 'small-screen' : ''}`}>
                                                    {description || category}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="second-page-list-item-icon">
                                            {hovered !== category ? 
                                                <PlayArrowOutlinedIcon style={{
                                                    width: screenSize === 'small' ? '6vw' : '3vw', 
                                                    height: screenSize === 'small' ? '7vw' : '3.5vw'
                                                }}/> :
                                                <PlayArrowIcon style={{
                                                    width: screenSize === 'small' ? '6vw' : '3vw', 
                                                    height: screenSize === 'small' ? '7vw' : '3.5vw'
                                                }}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                { screenSize !== 'small' && (
                    <div className="picture-content" ref={pictureRef}>
                        <img 
                            className="banner-image"
                            src={getImage(selections)}
                            alt="bannerImage"
                        />
                </div>
                )}
            </div>
        </div>
    )
}