export const Footer = (props) => {
    const {
        screenSize,
        primaryColor,
        iconColor,
    } = props;
    return (
        <div
            className={`app-footer ${screenSize === 'small' ? 'small-screen' : ''}`}
            style={{
                backgroundColor: primaryColor,
                color: iconColor,
            }}
        >
            Rios Partners all rights reserved. Veterans Navigator is developed by Rios Partners in partnership with NYC DVS. 
        </div>
    )
}